<script>
import { required, email } from 'vuelidate/lib/validators';
import { authMethods, authFackMethods, notificationMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';

export default {
    data() {
        return {
            email: '',
            password: '',
            submitted: false,
            iconEye: 'fa fa-eye-slash',
            loading: false
        };
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },
    created() {
        document.body.classList.add('auth-body-bg');
    },
    validations: {
        email: { required, email },
        password: {
            required,
            validate() {
                if (this.password && this.password.length < 255) {
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
                    if (passwordRegex.test(this.password)) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            }
        }
    },
    methods: {
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,

        tryToLogIn() {
            if (this.loading) return;
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
                    this.tryingToLogIn = true;
                    this.authError = null;
                    return this.logIn({
                        email: this.email,
                        password: this.password
                    })
                        .then(() => {
                            this.tryingToLogIn = false;
                            this.isAuthError = false;
                            this.$router.push(this.$route.query.redirectFrom || { name: 'home' });
                        })
                        .catch((error) => {
                            this.tryingToLogIn = false;
                            this.authError = error ? error : '';
                            this.isAuthError = true;
                        });
                } else {
                    this.loading = true;
                    const { email, password } = this;
                    if (email && password) {
                        this.login({ email, password }).then(async (data) => {
                            if (data.code == 200) {
                                await this.profile();
                                const redirectUrl = localStorage.getItem('redirectAfterLogin');
                                if (redirectUrl) {
                                    localStorage.removeItem('redirectAfterLogin');
                                    window.location.href = redirectUrl;
                                } else {
                                    this.$router.push({ path: '/' }).then(() => {
                                        showMessage(data.code, this.$bvToast, 'ログインが完了しました。');
                                    });
                                }
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }

                            this.loading = false;
                        });
                    }
                }
            }
        },
        handleTriggerPass() {
            let pass = document.getElementById('userpassword');
            if (pass.type == 'password') {
                pass.type = 'text';
                this.iconEye = 'fa fa-eye';
            } else {
                pass.type = 'password';
                this.iconEye = 'fa fa-eye-slash';
            }
        }
    }
};
</script>

<template>
    <div>
        <div>
            <div class="container-fluid p-0" style="background-color: #f9fafb">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="authentication-page-content min-vh-100 d-flex align-items-center justify-content-center">
                            <div class="d-flex align-items-center justify-content-center flex-wrap-reverse">
                                <div class="form-block_account">
                                    <div class="col-md-12 text-center mb-3">
                                        <img src="@/assets/images/logo-paldia.png" alt="Image" class="img-fluid" />
                                    </div>
                                    <div class="mb-4">
                                        <h3>ログイン <strong></strong></h3>
                                    </div>
                                    <form class="needs-validation" @submit.prevent="tryToLogIn" autocomplete="off">
                                        <div class="form-group first">
                                            <i class="ri-mail-line auti-custom-input-icon align-middle mr-1"></i>
                                            <label for="email"
                                                >ID
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input
                                                type="email"
                                                v-model="email"
                                                class="form-control"
                                                id="email"
                                                placeholder="ID"
                                                :class="{ 'is-invalid': submitted && $v.email.$error }"
                                            />
                                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                                <span v-if="!$v.email.required">メールアドレスを入力してください。</span>
                                                <span v-if="!$v.email.email">メールアドレスの形式は正しくありません。</span>
                                            </div>
                                        </div>
                                        <div class="form-group last mb-4">
                                            <i class="ri-lock-2-line auti-custom-input-icon align-middle mr-1"></i>
                                            <label for="userpassword"
                                                >パスワード
                                                <span class="text-danger">*</span>
                                            </label>
                                            <div
                                                class="inputBox"
                                                :class="{
                                                    'is-invalid': submitted && $v.password.$error
                                                }"
                                            >
                                                <input
                                                    v-model="password"
                                                    type="password"
                                                    class="form-control"
                                                    id="userpassword"
                                                    placeholder="パスワード入力"
                                                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                                                />
                                                <span @click="handleTriggerPass">
                                                    <i :class="iconEye" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            <div v-if="submitted && $v.password.$error" class="invalid-feedback">
                                                <span v-if="!$v.password.required"> パスワードを入力してください。</span>
                                                <span v-if="!$v.password.validate"
                                                    >パスワードは大文字、小文字、数字、特殊文字を含む8～32文字以内で入力してください。</span
                                                >
                                            </div>
                                        </div>

                                        <div class="d-flex mb-4 align-items-center">
                                            <router-link tag="a" to="/forgot-password" class="text-muted ml-auto">
                                                <span class=""><i class="mdi mdi-lock mr-1"></i> *パスワードを忘れた方</span>
                                            </router-link>
                                        </div>

                                        <div class="mt-4 text-center">
                                            <button class="btn btn-pill text-white btn-block btn-primary" type="submit">ログイン</button>
                                        </div>

                                        <span class="d-block text-center my-4 text-muted"> or sign in with</span>

                                        <div class="social-login text-center">
                                            <a href="#" class="facebook">
                                                <span class="icon-facebook mr-3"><i class="ri-facebook-circle-fill"></i></span>
                                            </a>
                                            <a href="#" class="twitter">
                                                <span class="icon-twitter mr-3"><i class="ri-twitter-fill"></i></span>
                                            </a>
                                            <a href="#" class="google">
                                                <span class="icon-google mr-3"><i class="ri-google-fill"></i></span>
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
.inputBox {
    position: relative;
    & input {
        padding-right: 26px;
    }
    & span {
        position: absolute;
        right: 5px;
        top: 10px;
        cursor: pointer;
        &:hover {
            color: #505d69cf;
        }
    }
}
</style>
